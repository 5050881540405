<template>
  <div
    class="pro-select"
    :class="{'light': light}"
  >
    <div
      v-if="label"
      class="pro-select-label"
    >
      {{ label }}
    </div>

    <div
      class="pro-select-inner"
      :class="{'light': light}"
      :style="{height: boxHeight}"
      @click="toggleDropdown"
    >
      <div
        v-if="iconClass"
        class="pro-select-icon"
        :class="((multiselect && selectedItems.length > 0) || (!multiselect && value))
          ? (iconColorClass || 'text-primary')
          : ''
        "
        :style="{ 'color': selectedItems.length === 1 ? `${selectedItems[0].color} !important` : '' }"
      >
        <i
          :class="selectedItems.length === 1 ? getItemIconClass(selectedItems[0]) : iconClass"
        />
      </div>

      <div
        v-if="showAllOption
          && ((multiselect && selectedItems.length === 0) || (!multiselect && !selectedItem))"
        class="pro-select-text"
      >
        {{ $t('general.all') }}
      </div>

      <div
        v-else-if="multiselect && selectedItems.length === 1"
        class="pro-select-text"
      >
        {{ getItemLabel(selectedItems[0]) }}
      </div>

      <div
        v-else-if="multiselect"
        class="pro-select-text"
      >
        {{ selectedItems.length }}
        {{ $t('general.selected') }}
      </div>

      <div
        v-else
        class="pro-select-text"
      >
        {{ getItemLabel(selectedItem) }}
      </div>

      <div class="pro-select-caret">
        <i class="fas fa-caret-down" />
      </div>
    </div>

    <div
      v-if="dropdownOpen"
      ref="dropdown"
      v-click-outside="handleClickOutside"
      class="pro-select-dropdown"
      :style="{width: dropdownWidth}"
    >
      <div
        v-if="showSearch"
        class="pro-select-dropdown-search"
      >
        <input
          v-model="searchQuery"
          class="form-control"
          :placeholder="$t('general.search')"
        >
      </div>
      <div
        v-if="showSearch"
        class="pro-select-dropdown-divider"
      />

      <div
        v-if="showAllOption"
        class="pro-select-dropdown-item"
        @click="setAllOption"
      >
        <div
          v-if="iconClass"
          class="pro-select-icon"
        >
          <i :class="iconClass" />
        </div>

        {{ $t('general.all') }}
      </div>
      <div
        v-if="showAllOption"
        class="pro-select-dropdown-divider"
      />

      <div
        v-for="item in filteredItems"
        :id="`pro-select-item-${getItemValue(item)}`"
        :key="getItemValue(item)"
        class="pro-select-dropdown-item"
        :class="{'selected': isItemSelected(item)}"
        @click="setValue(getItemValue(item))"
      >
        <div
          v-if="getItemIconClass(item)"
          class="pro-select-icon"
        >
          <i
            :class="getItemIconClass(item)"
            :style="{ 'color': item.color }"
          />
        </div>

        {{ getItemLabel(item) }}

        <div
          v-if="isItemSelected(item)"
          class="pro-select-dropdown-item-check"
        >
          <i class="fas fa-check" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProSelect',
  props: {
    value: [String, Number, Array],
    options: {
      type: Array,
      required: true,
    },
    multiselect: Boolean,
    showAllOption: Boolean,
    showSearch: Boolean,
    light: Boolean,
    iconClass: String,
    iconColorClass: String,
    dropdownWidth: {
      type: String,
      default: '150%',
    },
    boxHeight: {
      type: String,
      default: '32px',
    },
    label: {
      type: String,
      default: '',
    },
    customLabelExtractFunction: Function,
    customValueExtractFunction: Function,
  },
  data: () => ({
    dropdownOpen: false,
    searchQuery: '',
  }),
  computed: {
    allItems() {
      return [...this.options];
    },
    innerValue: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      },
    },
    filteredItems() {
      if (this.searchQuery) {
        const searchItems = this.allItems.filter(i => this.getItemLabel(i).toLowerCase()
          .includes(this.searchQuery.toLowerCase()));
        return searchItems;
      }
      return this.allItems;
    },
    selectedItems() {
      return this.allItems
        .filter(i => this.innerValue === this.getItemValue(i)) || [];
    },
    selectedItem() {
      return this.allItems
        .find(i => this.getItemValue(i) === this.innerValue);
    },
  },
  watch: {
    dropdownOpen(v) {
      if (!v) {
        this.searchQuery = '';
      } else {
        this.$nextTick(() => {
          this.scrollToSelectedItem();
        });
      }
    },
    searchQuery() {
      this.scrollToTop();
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    setValue(v) {
      if (this.multiselect) {
        const isSelected = this.innerValue?.includes(v);
        if (isSelected) {
          this.innerValue = this.innerValue.filter(value => value !== v);
        } else {
          this.innerValue = [
            ...this.innerValue,
            v,
          ];
        }
        return;
      }
      this.innerValue = v;
      this.dropdownOpen = false;
    },
    setAllOption() {
      if (this.multiselect) {
        this.innerValue = [];
        return;
      }
      this.setValue('');
    },
    handleClickOutside() {
      this.dropdownOpen = false;
    },
    isItemSelected(item) {
      if (this.multiselect) return this.innerValue?.includes(this.getItemValue(item));
      return this.getItemValue(item) === this.innerValue;
    },
    getItemValue(item) {
      if (this.customValueExtractFunction) return this.customValueExtractFunction(item);
      return item?.value;
    },
    getItemLabel(item) {
      if (this.customLabelExtractFunction) return this.customLabelExtractFunction(item);
      return item?.label || '-';
    },
    getItemIconClass(item) {
      return item?.iconClass || this.iconClass;
    },
    scrollToTop() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.scrollTo({
          top: 0,
        });
      }
    },
    scrollToSelectedItem() {
      if (this.multiselect) return;

      if (this.$refs.dropdown) {
        const selectedItemId = this.getItemValue(this.selectedItem);
        const scrollToElement = this.$refs.dropdown.querySelector(`#pro-select-item-${selectedItemId}`);
        const halfOfDropdown = this.$refs.dropdown.clientHeight / 2;
        if (!scrollToElement) return;
        this.$refs.dropdown.scrollTo({
          top: scrollToElement.offsetTop - halfOfDropdown,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .pro-select {
    display: inline-block;
    user-select: none;
    position: relative;

    &-inner {
      display: inline-flex;
      position: relative;
      padding: 10px;
      background-color: #F2F2F2;
      border-radius: 4px;
      margin-top: 1px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: 0.2s all;

      &.light {
        background-color: white;

        &:hover {
          background-color: #fefefe;
        }
      }

      &:hover {
        background-color: #e5e5e5;
      }
    }

    &-label {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: #848484;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-icon {
      width: 13px;
      height: 13px;
      font-size: 13px;
      line-height: 0;
      margin-right: 8px;
      align-items: center;
      justify-content: center;
      display: inline-block;
    }

    &-text {
      margin-right: 8px;
      white-space: nowrap;
    }

    &-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      border-radius: 4px;
      max-width: 300px;
      box-shadow: 0px 0px 10px 0px #85858540;
      max-height: 500%;
      overflow-y: auto;
      padding: 8px 0;
      z-index: 15000;

      &-search {
        width: 100%;
        height: 34px;
        position: sticky;
        top: 0;
        padding: 10px 8px 0 8px;
        z-index: 1;
        background-color: white;
        transform: translateY(-10px);

        > input {
          width: 100%;
          height: 100%;
          padding: 4px;
          font-size: 12px;
          border-radius: 2px;
        }
      }

      &-divider {
        width: 100%;
        height: 15px;
        position: relative;

        &:after {
          content: "";
          left: 2.5%;
          top: 7px;
          position: absolute;
          width: 95%;
          height: 1px;
          background-color: #E8E8E8;
        }
      }

      &-item {
        width: 100%;
        height: 26px;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;
        transition: 0.15s all;
        color: #4C5157;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover,
        &.selected {
          background-color: #EEEEEE;
        }

        &-check {
          margin-left: auto;
        }
      }
    }
  }
</style>
